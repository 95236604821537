/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-youtube': {
    width: 18,
    height: 13,
    viewBox: '0 0 18 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.163 8.763v-4.91c1.786.82 3.17 1.612 4.806 2.466-1.35.73-3.02 1.55-4.806 2.444zm9.955-7.353c-.308-.396-.833-.704-1.392-.806C14.082.299 3.83.298 2.187.604c-.448.082-.848.28-1.19.588C-.45 2.5.003 9.52.352 10.657c.146.492.335.847.574 1.08.307.308.728.52 1.21.615 1.354.274 8.325.426 13.56.041.482-.082.91-.3 1.246-.622 1.336-1.303 1.245-8.717.175-10.36z" _fill="#fff" fill-opacity=".4"/>'
  }
})
